var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Mannschaftsführer/Trainer")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getTrainer(0),"label":"Trainer 1","id":"null","clearable":""},on:{"input":function (ref) {
	var value = ref.value;

	return _vm.save(0, value);
}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getTrainer(1),"label":"Trainer 2","id":"null","clearable":""},on:{"input":function (ref) {
	var value = ref.value;

	return _vm.save(1, value);
}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }